import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  Alert,
  RadioGroup,
  FormControlLabel,
  Radio,
  Link,
  Stack,
  styled,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Chip,
} from "@mui/material";
import { HeaderCompoment } from "../../compoment";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utils/Formater";
import { useValue } from "../../context/ContextProvider";
import img from "../../themes/image";
import { PaymentCompleted } from "../../screen";
import moment from "moment";
import {
  reqBodyBuyLoto,
  reqBodyBuyLotoPayment,
  reqBodyPaymentByAlipay,
} from "../../body/bodyRequest";
import { reqBuyLottery, GetRath } from "../../actions/ResetClient";
import Color from "../../themes/Colors";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { WARNING } from "../../utils/Constant";
import { ContentCopy } from "@mui/icons-material";
import "./PaymentForm.css";
export default function OrderDetail() {
  const { t } = useTranslation();
  const {
    state: { currentUser, tokenInfo, dataBuySuccess, dataReth },
    dispatch,
  } = useValue();

  const Img = styled("img")({
    margin: "auto",
    display: "block",
    maxWidth: "80%",
    maxHeight: "80%",
    borderRadius: 10,
  });

  const navigate = useNavigate();
  const [addCart, setAddCart] = useState([]);
  const [list, setList] = useState(2);
  const [lotoBuy, setlotoBuy] = useState([]);
  const [total, setTotal] = useState(0);
  const [show, setshow] = useState(true);
  const [cusName, setCusName] = useState("");
  const [lotDate, setLotDate] = useState("");
  const [value, setValue] = useState(null);
  const [DoubleLuck, setLotdoubleLuck] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [rath, setRath] = useState(0);
  const [open, setOpen] = useState(false);
  const [openFromSubmit, setopenFromSubmit] = useState(false);
  const [formData, setFormData] = useState("");

  const [isCopied, setIsCopied] = useState(false);

  const handleCloseQR = () => {
    setOpen(false);
    setopenFromSubmit(false);
  };

  useEffect(() => {
    console.log("currentUser.whitelistAvoidQR:", currentUser?.whitelistAvoidQR);
    console.log("currentUser.viewImageLottery:", currentUser?.viewImageLottery);
    console.log("currentUser:", currentUser);

    // Handle addCart data from localStorage
    const addCart = JSON.parse(localStorage.getItem("addCart"));
    if (addCart?.length > 0) {
      setAddCart(addCart);

      const listArray = addCart.map((item) => ({
        lottoNumber: item.lottoNumber,
        price: item.lottoPrice,
        qty: item.qty,
        total: item.lottoPrice, // Is this intended? Should this be `item.lottoPrice * item.qty`?
      }));

      const totalBill = addCart.reduce(
        (acc, item) => acc + parseInt(item.lottoPrice, 10),
        0
      );

      setTotal(totalBill);
      setlotoBuy(listArray);
      setLotdoubleLuck(localStorage.getItem("doubleLuck") || "");
    }

    // Handle dataBuySuccess logic
    if (dataBuySuccess) {
      console.log("dataBuySuccess2:", dataBuySuccess);

      if (dataBuySuccess.responseCode === "00009") {
        const { payment } = dataBuySuccess;

        if (payment?.refno && payment?.merchantid && payment?.total) {
          console.log("Submit form");

          setopenFromSubmit(true);
          const data = {
            refno: payment.refno,
            merchantid: payment.merchantid,
            customeremail: payment.customeremail || "",
            productdetail: payment.productdetail || "",
            cc: payment.cc || "",
            total: payment.total,
            lang: payment.lang || "en",
          };
          console.log("setopenFromSubmit:", data);
          setFormData(data);
        } else {
          console.error("Incomplete payment data:", payment);
          // alert("Payment information is incomplete. Please try again.");
          navigate("/BuyCongestion");
        }
      } else if (
        dataBuySuccess.responseCode === "00000" &&
        dataBuySuccess.promptPayQR === "avoid_pay"
      ) {
        navigate("/PaymentSuccess");
      }
    }

    // Handle currentUser logic
    if (currentUser?.accInfo) {
      setCusName(
        `${currentUser.accInfo.cusName} ${currentUser.accInfo.cusSurname}`
      );
    }

    if (currentUser?.lotAvaiable?.length > 0) {
      setLotDate(currentUser.lotAvaiable[0].fromLotDate);
    }

    // Handle dataReth logic
    if (dataReth?.length === 0) {
      const token = tokenInfo.access_token;
      const param = "thb_lak";
      const displayAlert = false;
      GetRath(token, param, displayAlert, dispatch);
    } else if (dataReth) {
      setRath(dataReth.appTitleEn);
    }
  }, [dataBuySuccess, dataReth]);

  // useEffect(() => {
  //   console.log("currentUser.whitelistAvoidQR:", currentUser.whitelistAvoidQR);
  //   console.log("currentUser.viewImageLottery", currentUser.viewImageLottery);
  //   console.log("currentUser", currentUser);
  //   const addCart = JSON.parse(localStorage.getItem("addCart"));
  //   if (addCart) {
  //     setAddCart(addCart);
  //     if (addCart.length > 0) {
  //       let listArray = [];
  //       let totalBill = 0;
  //       for (let j = 0; j < addCart.length; j++) {
  //         listArray.push({
  //           lottoNumber: addCart[j].lottoNumber,
  //           price: addCart[j].lottoPrice,
  //           qty: addCart[j].qty,
  //           total: addCart[j].lottoPrice,
  //         });
  //         totalBill += parseInt(addCart[j].lottoPrice);
  //       }
  //       setTotal(totalBill);
  //       setlotoBuy(listArray);
  //     }
  //     setLotdoubleLuck(localStorage.getItem("doubleLuck"));
  //   }

  //   if (dataBuySuccess) {
  //     console.log("dataBuySuccess2:", dataBuySuccess);
  //     if (dataBuySuccess.responseCode === "00009") {
  //       if (
  //         dataBuySuccess.payment &&
  //         dataBuySuccess.payment.refno &&
  //         dataBuySuccess.payment.merchantid &&
  //         dataBuySuccess.payment.total
  //       ) {
  //         console.log("Submit form");
  //         setopenFromSubmit(true);
  //         const data = {
  //           refno: dataBuySuccess.payment.refno,
  //           merchantid: dataBuySuccess.payment.merchantid,
  //           customeremail: dataBuySuccess.payment.customeremail,
  //           productdetail: dataBuySuccess.payment.productdetail,
  //           cc: dataBuySuccess.payment.cc,
  //           total: dataBuySuccess.payment.total,
  //           lang: dataBuySuccess.payment.lang,
  //         };
  //         setFormData(data);
  //       }
  //       navigate("/BuyCongestion");
  //     } else if (
  //       dataBuySuccess.responseCode === "00000" &&
  //       dataBuySuccess.promptPayQR === "avoid_pay"
  //     ) {
  //       navigate("/PaymentSuccess");
  //     }
  //   }
  //   if (currentUser) {
  //     if (currentUser.accInfo) {
  //       setCusName(
  //         currentUser.accInfo.cusName + " " + currentUser.accInfo.cusSurname
  //       );
  //     }

  //     if (currentUser.lotAvaiable) {
  //       if (currentUser.lotAvaiable.length > 0) {
  //         setLotDate(currentUser.lotAvaiable[0].fromLotDate);
  //       }
  //     }
  //   }

  //   if (dataReth && dataReth.length === 0) {
  //     const token = tokenInfo.access_token;
  //     const param = "thb_lak";
  //     const displayAlert = false;
  //     GetRath(token, param, displayAlert, dispatch);
  //   } else {
  //     setRath(dataReth.appTitleEn);
  //   }
  // }, [dataBuySuccess, dataReth]);

  const onPayment = (bill, fileName) => {
    try {
      // dispatch({ type: 'OPEN_PAYMENT' })
      if (currentUser && tokenInfo) {
        if (currentUser.accInfo) {
          if (currentUser.lotAvaiable) {
            const token = tokenInfo.access_token;
            const billId = bill
              ? bill
              : moment(new Date()).format("YYMMDDHHmmss");
            const lotId = currentUser.lotAvaiable[0].lotId;
            const cusId = currentUser.accInfo.cusId;
            const cusName = currentUser.accInfo.cusName;
            const agentId = currentUser.agent
              ? currentUser.agent.agentId
              : null;
            const agentName = currentUser.agent
              ? currentUser.agent.agentName
              : null;
            const carriedCode = currentUser.accInfo.carriedCode;
            const carriedName = currentUser.accInfo.carriedName;
            const amount = total;
            const discount = 0;
            const totalAmount = total - discount;
            const tax = 0;
            const promoId = null;
            const doubleLuck = localStorage.getItem("doubleLuck");
            const paymentGateway = value ? value : "";
            const dataReq =
              currentUser.countryStatus && currentUser.viewImageLottery
                ? reqBodyBuyLotoPayment(
                    billId,
                    lotId,
                    cusId,
                    cusName,
                    agentId,
                    agentName,
                    amount,
                    discount,
                    totalAmount,
                    tax,
                    carriedCode,
                    carriedName,
                    promoId,
                    lotoBuy,
                    doubleLuck,
                    fileName
                  )
                : currentUser.accInfo.alipayStatus === "1"
                ? reqBodyPaymentByAlipay(
                    billId,
                    lotId,
                    cusId,
                    cusName,
                    agentId,
                    agentName,
                    amount,
                    discount,
                    totalAmount,
                    tax,
                    carriedCode,
                    carriedName,
                    promoId,
                    lotoBuy,
                    doubleLuck,
                    fileName,
                    paymentGateway
                  )
                : reqBodyBuyLoto(
                    billId,
                    lotId,
                    cusId,
                    cusName,
                    agentId,
                    agentName,
                    amount,
                    discount,
                    totalAmount,
                    tax,
                    carriedCode,
                    carriedName,
                    promoId,
                    lotoBuy,
                    doubleLuck
                  );
            console.log("-----Buy loto dataReq:", dataReq); // reqBodyBuyLotoPayment
            reqBuyLottery(token, dataReq, dispatch);
          } else {
            return dispatch({
              type: "UPDATE_ALERT",
              payload: {
                open: true,
                severity: "error",
                message: "Error Lot Avaiable",
              },
            });
          }
        } else {
          return dispatch({
            type: "UPDATE_ALERT",
            payload: {
              open: true,
              severity: "error",
              message: "Error Account Info",
            },
          });
        }
      } else {
        return dispatch({
          type: "UPDATE_ALERT",
          payload: {
            open: true,
            severity: "error",
            message: "Error Current User and token null, Pls check",
          },
        });
      }
    } catch (error) {
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: error,
        },
      });
    }
  };

  const handleChange = (event) => {
    // console.log("event.target.value:", event.target.value);
    setValue(event.target.value);
    setOpen(true);
  };

  const handleSelectChange = (event) => {
    console.log("event.target.value:", event.target.value);
    setValue(event.target.value);
  };

  const handleLinkClick = () => {
    // Trigger the click event of the file input
    document.getElementById("fileInput").click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    // console.log("Selected File:", file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const uploadBuilFile = () => {
    if (selectedFile && selectedFile.name) {
      // console.log("selectedFile.name:", selectedFile.name);
      // console.log("selectedFile.type:", selectedFile.type);
      let fileTypes = ["image/jpeg", "image/png"];
      let FileName = selectedFile.name;
      if (FileName && fileTypes.includes(selectedFile.type)) {
        dispatch({ type: "START_LOADING" });
        const billId = moment(new Date()).format("YYMMDDHHmmss");
        const dataUpload = new FormData();
        // console.log("billId:", billId)
        dataUpload.append("image", selectedFile); // image
        dataUpload.append("bill_id", billId);
        dataUpload.append("transType", "buy_lottery");
        fetch(
          "https://234society.com/api_upload_file/laos/v1/upload_statement.php",
          {
            method: "POST",
            body: dataUpload,
          }
        )
          .then((res) => res.json())
          .then((res) => {
            // console.log("res:", res);
            dispatch({ type: "END_LOADING" });
            if (res.status === true) {
              // console.log("res1111:", res);
              let fileName = res.file_name;
              onPayment(billId, fileName);
              dispatch({ type: "END_LOADING" });
            } else {
              dispatch({ type: "END_LOADING" });
              Swal.fire({
                icon: WARNING,
                title: t("alert"),
                text: res.message,
              });
            }
          })
          .catch((er) => {
            dispatch({ type: "END_LOADING" });
            return dispatch({
              type: "UPDATE_ALERT",
              payload: {
                open: true,
                severity: "error",
                message: er,
              },
            });
          });
      } else {
        dispatch({ type: "END_LOADING" });
        return dispatch({
          type: "UPDATE_ALERT",
          payload: {
            open: true,
            severity: "error",
            message: "Tyle file PNG, JPEG",
          },
        });
      }
    } else {
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Can not get image file name, pls check and try agian",
        },
      });
    }
  };

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  const handleCopyClick = (e) => {
    copyTextToClipboard(e)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    const form = document.createElement("form");
    form.method = "POST";
    form.action = "https://payments.paysolutions.asia/payment";

    // Add input fields to the form dynamically
    Object.entries(formData).forEach(([key, value]) => {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = value;
      form.appendChild(input);
    });

    document.body.appendChild(form);
    form.submit();
    // navigate("/BuyCongestion");
  };

  return (
    <Box component="main" sx={{ width: "100%" }}>
      <HeaderCompoment icon={true} icondat={false} textHeader="OrderSummary" />
      <br />
      <br />
      <br />
      <br />

      <div
        className="section wow fadeIn"
        style={{ visibility: "visible", animationName: "fadeIn" }}
      >
        <div className="card-info receipt">
          <table className="table-total receipt">
            <tbody>
              <tr>
                <td>{t("RecipientName")}</td>
                <td>{cusName}</td>
              </tr>
              <tr>
                <td>{t("installmentDate")}</td>
                <td>{lotDate}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-info receipt">
          <h5 class="mb-2">{t("items")} </h5>
          <table class="table-total receipt">
            <tbody>
              {addCart.length > 0
                ? addCart.map((row) => (
                    <tr key={row.lottoNumber}>
                      <td>{row.lottoNumber}</td>
                      <td class="text-center">x1</td>
                      <td>
                        {row.lottoPrice} {t("bat")}
                      </td>
                    </tr>
                  ))
                : null}
              <tr>
                <td colSpan={3}>
                  <hr className="dashed my-2" />
                </td>
              </tr>
              <tr class="total">
                <td>
                  <strong>{t("NumberItems")}</strong>
                </td>
                <td></td>
                <td>
                  {addCart.length} {t("item")}
                </td>
              </tr>
            </tbody>
          </table>
          {DoubleLuck === "2" ? (
            <Alert severity="success">{t("DoubleLuck")}</Alert>
          ) : null}
        </div>

        {/* <div className='card-info flex-column'>
          <h5> {t('items')} </h5>
        
          {
            addCart.length > 0 ? addCart.slice(0, list).map((row) => (
              <div key={row.id} className='lotto-lists pt-2'>
                <div className="card-lotto">
                  <div className="card-row row-1">
                    <div className="set s-2">
                      <span>{row.lotDate}</span>
                    </div>
                    <div className="logo">
                      <span className="icons" />
                      <span>{t('GovernmentLottery')}</span>
                    </div>
                   
                  </div>
                  <div className="card-row row-2">
                    <div className="number-lists textgroup">
                      <span style={{ letterSpacing: '0.5cm' }}>{row.lottoNumber}</span>
                    </div>
                  </div>
                  <div className="card-row row-3">
                    <div className="textgroup">
                      <span>{t('period')} {row.lotId}</span>
                      <span>{t('set')} 1</span>
                      <span className="viewer">{t('totalAndService')}</span>
                    </div>
                    <span className="price"><strong>{row.lottoPrice}</strong> {t('bat')}</span>
                  </div>
                </div>
              </div>

            )) : null
          }
          {
            addCart.length > 2 ? list != 1000 ?
              <div className="d-flex py-2">
                <button onClick={() => setList(1000)} className="btn btn-xs viewmore mx-auto">{t('SeeAll')}</button>
              </div> : null : null
          }

          {
            list === 1000 ?
              <div className="d-flex py-2">
                <button onClick={() => setList(3)} className="btn btn-xs viewmore mx-auto">{t('ShowLess')}</button>
              </div> : null
          }

        </div> */}

        {addCart ? (
          <div className="card-info receipt">
            <h5>{t("PriceSummary")}</h5>
            <table className="table-total receipt">
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <hr className="dashed my-2" />
                  </td>
                </tr>
                <tr>
                  <td>{t("TotalMoney")}</td>
                  <th>
                    {" "}
                    <p>
                      {formatNumber(
                        addCart.reduce(
                          (a, v) => (a = a + parseInt(v.lottoPrice)),
                          0
                        ) + ""
                      )}{" "}
                      {t("bat")}
                    </p>
                  </th>
                </tr>
                <tr>
                  <td>{t("rath")}</td>
                  <th>
                    <p>{rath || 0}</p>{" "}
                  </th>
                </tr>
                <tr>
                  <td>{t("UseTheDropPart")} 0%</td>
                  <th>
                    <p>0 {t("bat")}</p>
                  </th>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <hr className="dashed my-2" />
                  </td>
                </tr>
                <tr>
                  <td>{t("sumTotal")}</td>
                  <th>
                    <h3>
                      {formatNumber(
                        addCart.reduce(
                          (a, v) => (a = a + parseInt(v.lottoPrice)),
                          0
                        ) + ""
                      )}{" "}
                      {t("bat")}
                    </h3>
                  </th>
                </tr>

                {currentUser.countryStatus && currentUser.viewImageLottery ? (
                  <>
                    <tr>
                      <td colSpan={2}>
                        <hr className="dashed my-2" />
                      </td>
                    </tr>
                    <tr>
                      <td>{t("sumTotal")}</td>
                      <th>
                        <h3>
                          {formatNumber(
                            addCart.reduce(
                              (a, v) => (a = a + parseInt(v.lottoPrice) * rath),
                              0
                            ) + ""
                          )}{" "}
                          {t("lak")}
                        </h3>
                      </th>
                    </tr>
                  </>
                ) : null}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>

      {currentUser.countryStatus &&
      currentUser.viewImageLottery &&
      !currentUser.whitelistAvoidQR ? (
        <div className="section">
          <h5>{t("ChoosePaymentChannel")}</h5>
          {/* <Typography sx={{ color: Color.error, fontSize: 12 }}>
            {t("woningPaymentgateway")}
          </Typography> */}

          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
            sx={{ paddingBottom: 1 }}
          >
            <div className="form-check payment">
              <FormControlLabel
                value="umoney"
                control={<Radio />}
                label={t("umoneyPayment")}
              />
              <label className="form-check-label" htmlFor="creditcard">
                <div className="img">
                  <img className="h-30" src={img.iconu_money} alt="umoney" />
                </div>
              </label>
            </div>

            <div className="form-check payment">
              <FormControlLabel
                value="Onecash"
                control={<Radio />}
                label={t("oneCashPayment")}
              />
              <label className="form-check-label" htmlFor="mobileBanking">
                <div className="img">
                  <img
                    className="h-30"
                    src={img.icon_oneplay}
                    alt="oneCashPayment"
                  />
                </div>
              </label>
            </div>
          </RadioGroup>
          {value ? (
            <div className="card-info receipt">
              <h5>{t("uploadBillPayment")}</h5>
              <hr className="dashed my-2" />
              <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              {selectedFile ? (
                <Stack
                  direction="row"
                  sx={{
                    flexGrow: 1,
                    textAlign: "center",
                    justifyItems: "center",
                    height: "100%",
                  }}
                >
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => handleLinkClick()}
                  >
                    <Img alt="Banner discount" src={imageUrl} />
                  </Link>
                </Stack>
              ) : (
                <div className="form-check payment">
                  <label className="form-check-label" htmlFor="creditcard">
                    <>
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => handleLinkClick()}
                      >
                        <p>{t("woningImageFiletype")}</p>
                      </Link>
                      <div className="img">
                        <img
                          className="h-30"
                          src={img.bill}
                          alt="woningImageFiletype"
                        />
                      </div>
                    </>
                  </label>
                </div>
              )}
            </div>
          ) : null}
        </div>
      ) : null}

      {currentUser &&
      currentUser.accInfo &&
      currentUser.accInfo.alipayStatus === "1" ? (
        <div className="section">
          <h5>{t("ChoosePaymentChannel")}</h5>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleSelectChange}
            sx={{ paddingBottom: 1 }}
          >
            <div className="form-check payment">
              <FormControlLabel
                value="alipay"
                control={<Radio />}
                label={t("AlipayPayment")}
              />
              <label className="form-check-label" htmlFor="creditcard">
                <div className="img">
                  <img className="h-30" src={img.icAlipayLogo} alt="alipay" />
                </div>
              </label>
            </div>
          </RadioGroup>
        </div>
      ) : null}
      {/* onPayment() */}

      <div className="footer-fixed" style={{ height: "220.188px" }}>
        <div className="card-info card-fixed">
          {currentUser.countryStatus &&
          currentUser.viewImageLottery &&
          !currentUser.whitelistAvoidQR ? (
            selectedFile ? (
              <a
                className="btn btn-yellow btn-lg mb-2"
                onClick={() =>
                  !currentUser.countryStatus && currentUser.viewImageLottery
                    ? uploadBuilFile()
                    : onPayment()
                }
              >
                <p>{t("txtConfirmPayment")}</p>
              </a>
            ) : (
              <a className="btn btn-Grid btn-lg mb-2" onClick={() => null}>
                <p>{t("txtConfirmPayment")}</p>
              </a>
            )
          ) : currentUser &&
            currentUser.accInfo &&
            currentUser.accInfo.alipayStatus === "1" ? (
            value === "alipay" ? (
              <a
                className="btn btn-yellow btn-lg mb-2"
                onClick={() => onPayment()}
              >
                <p>{t("txtConfirmPayment")}</p>
              </a>
            ) : (
              <a className="btn btn-Grid btn-lg mb-2" onClick={() => null}>
                <p>{t("txtConfirmPayment")}</p>
              </a>
            )
          ) : (
            <a
              className="btn btn-yellow btn-lg mb-2"
              onClick={() => onPayment()}
            >
              <p>{t("txtConfirmPayment")}</p>
            </a>
          )}
        </div>
      </div>

      {/* {
        addCart && addCart.length > 0 ?
          <div className="footer-fixed" style={{ height: '220.188px' }}>
            <div className="card-info card-fixed">
              <div className="card-body">
                <table className="table table-borderless table-total">
                  <tbody><tr>
                    <td><h6>{t('PleaseConfirmWithin')}</h6></td>
                    <td><h5 style={{ color: '#FF0000' }}>05 : 00</h5></td>
                  </tr>
                    <tr>
                      <td colSpan={2}>
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td>{t('NumberItems')}</td>
                      <td>{addCart.length} {t('item')}</td>
                    </tr>
                    <tr>
                      <td>{t('SumMoney')}</td>
                      <td>{formatNumber(addCart.reduce((a, v) => a = a + parseInt(v.lottoPrice), 0) + "")} {t('bat')}</td>
                    </tr>
                    <tr>
                      <td>{t('BalanceMustPaid')}</td>
                      <td className='fw-semibold fs-20'>{formatNumber(addCart.reduce((a, v) => a = a + parseInt(v.lottoPrice), 0) + "")} {t('bat')}</td>
                    </tr>
                  </tbody></table>
              </div>
              <a className="btn btn-yellow btn-lg mb-2" onClick={() => onPayment()}>{t('Continue')}</a>
            </div>
          </div>
          : null
      } */}
      <PaymentCompleted />

      <Dialog
        open={open}
        onClose={handleCloseQR}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {value === "umoney" ? (
              <>
                <div className="img">
                  <img className="img-QRcode" src={img.img_umQR} alt="" />
                </div>
                <div
                  style={{
                    textAlign: "center",
                    padding: 15,
                    alignContent: "center",
                  }}
                >
                  <Chip
                    label="8562094104473"
                    variant="outlined"
                    onClick={() => handleCopyClick("8562094104473")}
                    icon={<ContentCopy />}
                  />
                  <p></p>
                  {isCopied ? (
                    <Chip
                      label={t("success")}
                      color="success"
                      variant="outlined"
                    />
                  ) : null}
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    textAlign: "center",
                    padding: 15,
                    alignContent: "center",
                  }}
                >
                  <Chip
                    label="8562094104473"
                    variant="outlined"
                    onClick={() => handleCopyClick("8562094104473")}
                    icon={<ContentCopy />}
                  />
                  <p></p>
                  {isCopied ? (
                    <Chip
                      label={t("success")}
                      color="success"
                      variant="outlined"
                    />
                  ) : null}
                </div>
                <div className="img">
                  <img className="img-QRcode1" src={img.flowPayQR} alt="" />
                </div>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseQR}>{t("cancel")}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openFromSubmit} fullScreen>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {formData ? (
              <>
                <HeaderCompoment
                  icon={false}
                  icondat={false}
                  textHeader="AlipayPayment"
                />
                <br />
                <br />
                <br />

                <div className="form-container">
                  {/* Add your logo here */}
                  <img
                    src={img.icAlipayLogo} // Replace with the actual logo path
                    alt="Logo"
                    className="form-logo"
                  />
                  <h1 className="form-title">Payment Form</h1>
                  <form className="payment-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label className="form-label">
                        Ref No:
                        <p className="form-value">{formData.refno}</p>
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        Merchant ID:
                        <p className="form-value">{formData.merchantid}</p>
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        Customer Email:
                        <p className="form-value">{formData.customeremail}</p>
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        Product Detail:
                        <p className="form-value">{formData.productdetail}</p>
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        Currency Code (CC):
                        <p className="form-value">{formData.cc}</p>
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        Total:
                        <p className="form-value">{formData.total}</p>
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        Language:
                        <p className="form-value">{formData.lang}</p>
                      </label>
                    </div>
                    <button className="form-button" type="submit">
                      Submit
                    </button>
                  </form>
                </div>
              </>
            ) : null}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
